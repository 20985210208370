<template>
  <div class="dlabnav bg-light">
    <div class="nav-header">
      <a href="javascript:;" class="d-flex flex-row justify-content-center" @click="goHomePage">
        <img class="mt-3 side-logo" src="../assets/images/logoo.svg" alt="" style="width: 90px" />
        <img class="brand-title" src="../assets/images/yazi.svg" alt="" />
      </a>

      <div class="nav-control">
        <div class="hamburger"><span class="line"></span><span class="line"></span><span class="line"></span></div>
      </div>
    </div>
    <div class="h-100">
      <perfect-scrollbar>
        <ul class="metismenu" id="menu">
          <li v-if="(user.IsCarrier || user.IsSwitched) && $store.state.User.token">
            <div
              class="
                col-md-12
                d-flex
                justify-content-between
                align-items-center
                notifications
                bg-light
                border-radius
                py-2
                px-3
                mb-4
                flex-wrap
              "
            >
              <div class="depot-name fs-16 ms-md-n3 ms-lg-0 pb-md-2 pb-lg-0 text-md-center">
                {{ user.IsCarrier ? "Tedarikçi ol" : "Nakliyeci ol" }}
              </div>
              <div>
                <el-switch v-model="roleSwitch" @change="handleRoleSwitch" active-color="#E9501C" inactive-color="#2D405A"> </el-switch>
              </div>
            </div>
          </li>
          <li class="dropdown header-profile">
            <a
              class="nav-link"
              href="javascript:void(0);"
              role="button"
              data-bs-toggle="dropdown"
              @click="
                () => {
                  if ($store.state.User.token) {
                    $router.push({ path: '/profil' });
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <img :src="user.Image ? user.Image : require('.././assets/images/profile/user_Profile.svg')" width="20" alt="" />
              <div class="header-info ms-3 ps-0">
                <span class="font-w600 d-block d-md-none d-lg-block mb-0">
                  Hoşgeldin, <br />
                  <b v-if="user.Title">{{ user.Title }}</b>
                  <b v-else>Yabancı</b>
                </span>
                <!-- <small class="text-end font-w400">{{$store.state.User.user.Email}}</small> -->
              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="./app-profile.html" class="dropdown-item ai-icon">
                <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
                <span class="ms-2">Profile </span>
              </a>
              <a href="./email-inbox.html" class="dropdown-item ai-icon">
                <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" class="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                  <polyline points="22,6 12,13 2,6"></polyline>
                </svg>
                <span class="ms-2">Inbox </span>
              </a>
              <a href="./login.html" class="dropdown-item ai-icon">
                <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                  <polyline points="16 17 21 12 16 7"></polyline>
                  <line x1="21" y1="12" x2="9" y2="12"></line>
                </svg>
                <span class="ms-2">Logout </span>
              </a>
            </div>
          </li>
          <li class="">
            <a
              href="javascript:;"
              @click="
                () => {
                  if ($store.state.User.token) {
                    closeSideBar();
                    $router.push({
                      name: 'homedashboard',
                    });
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/home.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Anasayfa</span>
            </a>
          </li>
          <li v-if="user.IsDealer">
            <a
              href="javascript:;"
              @click="
                () => {
                  closeSideBar();
                  $router.push('/dealer');
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/profile.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Bayi</span>
            </a>
          </li>
          <li class="">
            <a
              href="javascript:;"
              @click="
                () => {
                  closeSideBar();
                  $router.push('/ilanlar/aktif');
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/adverts2.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">İlanlar</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="
                () => {
                  if ($store.state.User.token) {
                    closeSideBar();
                    $router.push('/sevkiyatlarim');
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/deliverys.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Sevkiyatlarım</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="
                () => {
                  if ($store.state.User.token) {
                    closeSideBar();
                    $router.push('/degerlendirmelerim');
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/vote.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Değerlendirmelerim</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="
                () => {
                  if ($store.state.User.token) {
                    closeSideBar();
                    $router.push('/magaza');
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/store.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Mağaza</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="
                () => {
                  if ($store.state.User.token) {
                    closeSideBar();
                    $router.push('/profil');
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/profile.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Profil</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:;"
              @click="
                () => {
                  if ($store.state.User.token) {
                    closeSideBar();
                    $router.push('/reviewForm');
                  } else {
                    openLoginDialog();
                  }
                }
              "
            >
              <i>
                <img src="../assets/icons/nkicon/vote.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Görüş ve Öneriler</span>
            </a>
          </li>
          <li>
            <a @click="downloadAppDialog = true" href="javascript:;">
              <i>
                <img src="../assets/icons/nkicon/download.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Uygulamayı İndir</span>
            </a>
          </li>
          <li class="d-block d-md-none">
            <a @click="howToUseDialog = true" style="cursor: pointer">
              <i>
                <img src="../assets/icons/nkicon/question-mark.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Nasıl Kullanılır</span>
            </a>
          </li>
          <li>
            <a @click="signOutDialog = true" href="javascript:;">
              <i>
                <img src="../assets/icons/nkicon/log-out.svg" alt="" style="width: 20px" />
              </i>
              <span class="nav-text">Çıkış</span>
            </a>
          </li>
        </ul>
      </perfect-scrollbar>
    </div>

    <el-dialog :close-on-click-modal="false" class="signOutDialog downloadAppDialog" title="Uygulamayı İndir" :visible.sync="downloadAppDialog" :modalAppendToBody="false">
      <div class="d-flex flex-column justify-content-around w-100">
        <div class="d-flex flex-column justify-content-center w-100">
          <small class="text-center">QR Okutun</small>
          <img src="../assets/images/onlink_to_f62c6k.svg" class="qr-image" alt="apple-store-image" />
        </div>
        <div class="download-img-container">
          <a href="https://play.google.com/store/apps/details?id=com.kufegroup.nakliye_kullan&hl=tr&gl=US"> <img src="../assets/images/googleplay-tr.svg" class="download-image google-i" alt="google-play-image"/></a>
          <a href="https://apps.apple.com/tr/app/nakliyekullan/id1591921060?l=tr"> <img src="../assets/images/applestore-tr.svg" class="download-image apple-i" alt="apple-store-image"/></a>
        </div>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" class="signOutDialog" title="Çıkış Yap" :visible.sync="signOutDialog" :modalAppendToBody="false">
      <h6>Uygulamadan çıkış yapmak istediğinize emin misiniz?</h6>
      <span>Çıkış Yap Uygulamadan çıkış yapmak istediğinize emin misiniz? Çıkış yaptıktan sonra yine aynı e-posta ve şifre ile giriş yapabilirsiniz. Sorunlar için destek@nakliyekullan.com ile iletişime geçebilirsiniz.</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="signOutDialog = false">İptal</el-button>
        <el-button :loading="loading" @click="signOut">Çıkış Yap</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as $ from "jquery";
export default {
  name: "Sidebar",

  data() {
    return {
      downloadAppDialog: false,
      signOutDialog: false,
    };
  },
  computed: {
    howToUseDialog: {
      get() {
        return this.$store.getters["howToUseDialog"];
      },
      set(newValue) {
        this.$store.commit("howToUseDialog", newValue);
      },
    },
    systemSettings() {
      return this.$store.getters["userState"];
    },
    user() {
      return this.$store.getters["userState"];
    },
    loading() {
      return this.$store.getters["loading"];
    },
    roleSwitch: {
      get() {
        return !this.user.IsCarrier
      },
      // set(newValue) {
      //   //
      // }
    },
  },
  // created() {
  //   if (this.roleSwitch == null) this.roleSwitch = !this.user.IsCarrier;
  // },
  mounted() {
    $(".nav-control").on("click", function() {
      $("#main-wrapper").toggleClass("menu-toggle");

      $(".hamburger").toggleClass("is-active");
    });
  },

  methods: {
    openLoginDialog() {
      this.$store.commit("toggleLoginDialog", true);
    },
    goHomePage() {
      if (this.$store.state.User.token) {
        this.$router.push({ name: "homedashboard" });
      } else {
        this.$router.push({ name: "Landingpage" });
      }
    },
    async handleRoleSwitch(value) {
      if (this.$store.state.User.token) {
        const result = await this.$store.dispatch("switchRole");
        if (!result) this.roleSwitch = !value;
        else {
          this.$router.push({ name: "homedashboard" })
          window.location.reload()
        }

      } else {
        this.$store.commit("toggleLoginDialog", true);
        this.roleSwitch = !value;
      }
    },
    signOut() {
      this.signOutDialog = false;
      this.$store.dispatch("signOutUser");
    },
    closeSideBar() {
      if (screen.width <= 768) {
        $("#main-wrapper").toggleClass("menu-toggle");
        $(".hamburger").toggleClass("is-active");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ps {
  height: 100%;
}
span {
  color: #fff;
}
.download-link {
  display: inline-block;
}
.download-image {
  padding: 5px;
  width: 200px;
  margin-bottom: 5px;
}
.qr-image {
  padding: 5px;
  width: 200px;
  align-self: center;
  border-radius: 10px;
}
.download-img-container {
  display: flex;
  justify-content: center;
}
.apple-i {
  padding-right: 35px;
}
.google-i {
  padding-left: 35px;
}
@media (max-width: 768px) {
  [data-sidebar-style="overlay"] .dlabnav {
    left: -100% !important;
  }
  .download-img-container {
    justify-content: center;
    flex-direction: column;
    a {
      display: flex;
      justify-content: center;
    }
    img {
      padding: 0;
    }
  }
}

// breakpoint changed to 1024 cause somehow sidebar changes on w1024
@media (min-width: 768px) and (max-width: 1023.98px) {
  .ms-md-n3 {
    margin-left: -1rem !important;
  }
}
</style>
